function newDate(timestamp) {
  let date = parseInt(timestamp, 16);
  date *= 1000;
  let datee = new Date(date);
  return datee.toString();
}
const UserData = ({ users }) => {
  return (
    <>
      {users.map((curUser) => {
        let { blockchain, to, from, hash, timestamp } = curUser;

        return (
          <tr key={hash}>
            <td>{blockchain}</td>
            <td>{newDate(timestamp)}</td>
            <td>{to}</td>
            <td>{from}</td>
            <td>{hash}</td>
          </tr>
        );
      })}
    </>
  );
};
export default UserData;
