import { useEffect, useState } from "react";
import * as React from "react";
import UserData from "./components/UserData.jsx";
import { AnkrProvider } from "@ankr.com/ankr.js";
import { BigNumber, ethers } from "ethers";
import BarLoader from "react-spinners/BarLoader";
import PacmanLoader from "react-spinners/PacmanLoader";

const provider1 = new AnkrProvider(
  "https://rpc.ankr.com/multichain/63893b9c83fa6eed823c477b4d96aa425d5aeb8e188be3e021d3e4fa1a7528b9"
);
const provider2 = new AnkrProvider(
  "https://rpc.ankr.com/multichain/cb02286aad536b98e66ef72514f743d14d5bd879a16a5d870d85f934cbf5ffeb"
);
const provider3 = new AnkrProvider(
  "https://rpc.ankr.com/multichain/ce584693ba2dbb9776521d50c6005e259c5ded9abf6f3c99818a2af3225f9284"
);
const provider4 = new AnkrProvider(
  "https://rpc.ankr.com/multichain/3da23422b12102ad249a787864e59f4246d41743c9360a6eea88bef7fcc16553"
);
const provider5 = new AnkrProvider(
  "https://rpc.ankr.com/multichain/09aa865912ef9d73e71b6e338ceccc43b2831d2e923a14b7d5e3a452d5752a2a"
);
const provider6 = new AnkrProvider(
  "https://rpc.ankr.com/multichain/c386860665c23de6c8a99a6997ad628a47e6bf648bfedf77e0281aac05e3c558"
);

const DCAcontractAddress = [
  "0x75D046313b917504e899d45c4ddD3e1fFb8CC8ae",
  "0xb3664dF6baC51745366dAdc6Dc986A1A2524EeA5",
];
let priceNativeToUSD = {};
const transactions = async (blockchain, toBlock, address, fromBlock) => {
  let providerr;
  if (toBlock % 6 === 0) providerr = provider1;
  else if (toBlock % 6 === 1) providerr = provider2;
  else if (toBlock % 6 === 2) providerr = provider3;
  else if (toBlock % 6 === 3) providerr = provider4;
  else if (toBlock % 6 === 4) providerr = provider5;
  else providerr = provider6;
  let provi = new ethers.providers.JsonRpcProvider(
    `https://rpc.ankr.com/${blockchain}/1d24204797ff3f45515d739e2028ca3b3e7255ef44e82f2077f38132aa890918`
  );

  if (blockchain === "arbitrum") {
    const contract = new ethers.Contract(
      DCAcontractAddress[1],
      ["function getLatestData() public view returns (int256)"],
      provi
    );
    let works = await contract.getLatestData();
    priceNativeToUSD[blockchain] = works.toString();
  } else {
    const contract = new ethers.Contract(
      DCAcontractAddress[0],
      ["function getLatestData() public view returns (int256)"],
      provi
    );
    let works = await contract.getLatestData();
    priceNativeToUSD[blockchain] = works.toString();
  }

  let blockNum = await provi.getBlockNumber();
  return await providerr.getTransactionsByAddress({
    blockchain: blockchain,
    fromBlock: fromBlock,
    toBlock: blockNum.toString(),
    address: [address],
    pageToken: "",
    pageSize: 1000,
    descOrder: true,
    includeLogs: true,
  });
};

const App = () => {
  const [users, setUsers] = useState([]);
  const [users1, setUsers1] = useState([]);
  const [users2, setUsers2] = useState([]);
  const [users3, setUsers3] = useState([]);
  const [users4, setUsers4] = useState([]);
  const [users5, setUsers5] = useState([]);
  const [PolySum, setPolySum] = useState([]);
  const [BscSum, setBscSum] = useState([]);
  const [FantSum, setFantSum] = useState([]);
  const [AvalSum, setAvalSum] = useState([]);
  const [ArbiSum, setArbiSum] = useState([]);
  const [OptimismSum, setOptSum] = useState([]);
  const [showPolygon, setShowPoly] = useState(true);
  const [showBsc, setShowBsc] = useState(true);
  const [showArbi, setShowArbi] = useState(true);
  const [showAval, setShowAval] = useState(true);
  const [showFant, setShowFant] = useState(true);
  const [showOpt, setShowOpt] = useState(true);
  const [loading, setLoading] = useState(true);
  const [logged, setLogged] = useState(false);
let account;
  let sumPoly, sumBsc, sumArbi, sumAval, sumFant, sumOpt;

  const { utils } = require("ethers");

  const handleLogin = () => {
    if (window.ethereum ) {
      console.log("MetaMask Here!");
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((result) => {
          console.log(result);
          account=utils.getAddress(result[0]);
          console.log(account)
          if(account.toLowerCase()==="0xbaf59b045c6b53bcc849e2a487c14f234435cc51"){
            setLogged(true);
          }
        })
        .catch((error) => {
          console.log("Could not detect Account");
        });
    } else {
      console.log("Need to install MetaMask");
    }
  };

  const fetchPrice = (blockchain, volume) => {
    let vol = BigNumber.from(volume)
      .mul(priceNativeToUSD[blockchain])
      .div(BigNumber.from(10).pow(8))
      .div(BigNumber.from(10).pow(18));
    return vol.toString();
  };

  const fetchUsers = async () => {
    try {
      const trans1 = await transactions(
        "polygon",
        1,
        "0xC444435677D859DB1520d59E00557258b20Df8d8",
        43378667
      );
      const trans2 = await transactions(
        "bsc",
        2,
        "0x3065F648aAA6Df32Ef0223716411C73C1808DF95",
        28700558
      );
      const trans3 = await transactions(
        "arbitrum",
        3,
        "0x2847efCF4Ac0D239A431697d0B7ABf6db90CA730",
        96440860
      );
      const trans4 = await transactions(
        "avalanche",
        4,
        "0xA16FFA7274bfF034364f86cDB69BE7e1eBBeC334",
        30741301
      );
      const trans5 = await transactions(
        "fantom",
        5,
        "0xA16FFA7274bfF034364f86cDB69BE7e1eBBeC334",
        63380664
      );
      const trans6 = await transactions(
        "optimism",
        6,
        "0xc62cf7aF3CB61FedEfaF135c5a7b52f6AC265e76",
        105652274
      );

      if (trans1.transactions.length > 0) {
        setUsers(trans1.transactions);
      }
      if (trans2.transactions.length > 0) {
        setUsers1(trans2.transactions);
      }
      if (trans3.transactions.length > 0) {
        setUsers2(trans3.transactions);
      }
      if (trans4.transactions.length > 0) {
        setUsers3(trans4.transactions);
      }
      if (trans5.transactions.length > 0) {
        setUsers4(trans5.transactions);
      }
      if (trans6.transactions.length > 0) {
        setUsers5(trans6.transactions);
      }

      // console.log(priceNativeToUSD);

      let sum = 0;

      for (let i in trans1.transactions) {
        let data = trans1.transactions[i].input;
        let value = trans1.transactions[i].value;
        if (data.length > 1000 && data.length < 15000) {
          let decodeData = ethers.utils.defaultAbiCoder.decode(
            [
              "address[]",
              "address[]",
              "address[]",
              "address[]",
              "bytes[]",
              "uint256[]",
            ],
            ethers.utils.hexDataSlice(data, 4)
          );

          for (let k in decodeData[0]) {
            if (
              decodeData[0][k] ===
                "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270" ||
              decodeData[0][k] === "0x0000000000000000000000000000000000000000"
            ) {
              sum = BigNumber.from(sum).add(decodeData[5][k].toString());
            }
          }
        }
        sum = BigNumber.from(sum.toString()).add(value);
        setPolySum(sum.toString());
        sumPoly = sum;
      }
      sum = 0;
      for (let i in trans2.transactions) {
        let data = trans2.transactions[i].input;
        let value = trans2.transactions[i].value;
        if (data.length > 1000 && data.length < 15000) {
          let decodeData = ethers.utils.defaultAbiCoder.decode(
            [
              "address[]",
              "address[]",
              "address[]",
              "address[]",
              "bytes[]",
              "uint256[]",
            ],
            ethers.utils.hexDataSlice(data, 4)
          );

          for (let k in decodeData[0]) {
            if (
              decodeData[0][k] ===
                "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c" ||
              decodeData[0][k] === "0x0000000000000000000000000000000000000000"
            ) {
              sum = BigNumber.from(sum).add(decodeData[5][k].toString());
            }
          }
        }
        sum = BigNumber.from(sum.toString()).add(value.toString());
        setBscSum(sum.toString());
        sumBsc = sum;
      }
      sum = 0;
      for (let i in trans3.transactions) {
        let data = trans3.transactions[i].input;
        let value = trans3.transactions[i].value;
        if (data.length > 1000 && data.length < 15000) {
          let decodeData = ethers.utils.defaultAbiCoder.decode(
            [
              "address[]",
              "address[]",
              "address[]",
              "address[]",
              "bytes[]",
              "uint256[]",
            ],
            ethers.utils.hexDataSlice(data, 4)
          );

          for (let k in decodeData[0]) {
            if (
              decodeData[0][k] ===
                "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1" ||
              decodeData[0][k] === "0x0000000000000000000000000000000000000000"
            ) {
              sum = BigNumber.from(sum).add(decodeData[5][k].toString());
            }
          }
        }

        sum = BigNumber.from(sum.toString()).add(value.toString());

        setArbiSum(sum.toString());
        sumArbi = sum;
      }
      sum = 0;
      for (let i in trans4.transactions) {
        let data = trans4.transactions[i].input;
        let value = trans4.transactions[i].value;
        if (data.length > 1000 && data.length < 15000) {
          let decodeData = ethers.utils.defaultAbiCoder.decode(
            [
              "address[]",
              "address[]",
              "address[]",
              "address[]",
              "bytes[]",
              "uint256[]",
            ],
            ethers.utils.hexDataSlice(data, 4)
          );

          for (let k in decodeData[0]) {
            if (
              decodeData[0][k] ===
                "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7" ||
              decodeData[0][k] === "0x0000000000000000000000000000000000000000"
            ) {
              sum = BigNumber.from(sum).add(decodeData[5][k].toString());
            }
          }
        }

        sum = BigNumber.from(sum.toString()).add(value.toString());

        setAvalSum(sum.toString());
        sumAval = sum;
      }
      sum = 0;
      for (let i in trans5.transactions) {
        let data = trans5.transactions[i].input;
        let value = trans5.transactions[i].value;
        if (data.length > 1000 && data.length < 15000) {
          let decodeData = ethers.utils.defaultAbiCoder.decode(
            [
              "address[]",
              "address[]",
              "address[]",
              "address[]",
              "bytes[]",
              "uint256[]",
            ],
            ethers.utils.hexDataSlice(data, 4)
          );

          for (let k in decodeData[0]) {
            if (
              decodeData[0][k] ===
                "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83" ||
              decodeData[0][k] === "0x0000000000000000000000000000000000000000"
            ) {
              sum = BigNumber.from(sum).add(decodeData[5][k].toString());
            }
          }
        }

        sum = BigNumber.from(sum.toString()).add(value.toString());

        setFantSum(sum.toString());
        sumFant = sum;
      }
      sum = 0;
      for (let i in trans6.transactions) {
        let data = trans6.transactions[i].input;
        let value = trans6.transactions[i].value;
        if (data.length > 1000 && data.length < 15000) {
          let decodeData = ethers.utils.defaultAbiCoder.decode(
            [
              "address[]",
              "address[]",
              "address[]",
              "address[]",
              "bytes[]",
              "uint256[]",
            ],
            ethers.utils.hexDataSlice(data, 4)
          );

          for (let k in decodeData[0]) {
            if (
              decodeData[0][k] ===
                "0x4200000000000000000000000000000000000006" ||
              decodeData[0][k] === "0x0000000000000000000000000000000000000000"
            ) {
              sum = BigNumber.from(sum).add(decodeData[5][k].toString());
            }
          }
        }
        sum = BigNumber.from(sum.toString()).add(value.toString());
        setOptSum(sum.toString());
        sumOpt = sum;
      }

      setPolySum(fetchPrice("polygon", sumPoly));
      setBscSum(fetchPrice("bsc", sumBsc));
      setFantSum(fetchPrice("fantom", sumFant));
      setAvalSum(fetchPrice("avalanche", sumAval));
      setArbiSum(fetchPrice("arbitrum", sumArbi));
      setOptSum(fetchPrice("optimism", sumOpt));
    } catch (e) {
      console.error(e);
    }
  };
  let USDollar = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 10000);
    fetchUsers();
  }, []);
  return (
    <div>
      {!logged ? (
        <div className="LoginOption">
          <h1 className="LoginOptionHading">Log in with Metamask wallet</h1>
          <button className="LoginOptionButton" onClick={handleLogin}>Log In</button>
        </div>
      ) : (
    <div>
      {loading ? (
        <>
          <BarLoader
            color="rgb(122, 80, 161)"
            speedMultiplier={0.005}
            width={10000}
          />
          <PacmanLoader
            color="rgb(122, 80, 161)"
            speedMultiplier={1}
            size={50}
            cssOverride={{
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "0",
              marginTop: "35vh",
            }}
          />
          <p className="wait">wait for some sec...</p>
        </>
      ) : (
        <>
          <div className="Heading">Alpha Vault Transaction Tracker</div>
          <>
            <div className="tableContainer">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Polygon</th>
                    <th>Binance</th>
                    <th>Arbitrium</th>
                    <th>Avalanche</th>
                    <th>Fantom</th>
                    <th>Optimism</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr key="Transaction No.">
                    <td>Transaction No.</td>
                    <td>{users.length}</td>
                    <td>{users1.length}</td>
                    <td>{users2.length}</td>
                    <td>{users3.length}</td>
                    <td>{users4.length}</td>
                    <td>{users5.length}</td>
                    <td>
                      {users5.length +
                        users4.length +
                        users3.length +
                        users2.length +
                        users1.length +
                        users.length}
                    </td>
                  </tr>
                  <tr key="Transaction Vol.">
                    <td>Transaction Vol.</td>
                    <td>{USDollar.format(PolySum)}</td>
                    <td>{USDollar.format(BscSum)}</td>
                    <td>{USDollar.format(ArbiSum)}</td>
                    <td>{USDollar.format(AvalSum)}</td>
                    <td>{USDollar.format(FantSum)}</td>
                    <td>{USDollar.format(OptimismSum)}</td>
                    <td>
                      {USDollar.format(
                        +PolySum +
                          +BscSum +
                          +ArbiSum +
                          +FantSum +
                          +AvalSum +
                          +OptimismSum
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
          <hr />
          <hr />
          <div className="button-container">
            <button
              className="custom-button"
              onClick={() => {
                setShowBsc(true);
                setShowPoly(true);
                setShowArbi(true);
                setShowAval(true);
                setShowFant(true);
                setShowOpt(true);
              }}
            >
              All Chains
            </button>
            <button
              className="custom-button"
              onClick={() => {
                setShowPoly(true);
                setShowBsc(false);
                setShowArbi(false);
                setShowAval(false);
                setShowFant(false);
                setShowOpt(false);
              }}
            >
              Polygon
            </button>

            <button
              className="custom-button"
              onClick={() => {
                setShowPoly(false);
                setShowBsc(true);
                setShowArbi(false);
                setShowAval(false);
                setShowFant(false);
                setShowOpt(false);
              }}
            >
              Binance
            </button>
            <button
              className="custom-button"
              onClick={() => {
                setShowPoly(false);
                setShowBsc(false);
                setShowArbi(true);
                setShowAval(false);
                setShowFant(false);
                setShowOpt(false);
              }}
            >
              Arbitrum
            </button>
            <button
              className="custom-button"
              onClick={() => {
                setShowPoly(false);
                setShowBsc(false);
                setShowArbi(false);
                setShowAval(true);
                setShowFant(false);
                setShowOpt(false);
              }}
            >
              Avalanche
            </button>
            <button
              className="custom-button"
              onClick={() => {
                setShowPoly(false);
                setShowBsc(false);
                setShowArbi(false);
                setShowAval(false);
                setShowFant(true);
                setShowOpt(false);
              }}
            >
              Fantom
            </button>
            <button
              className="custom-button"
              onClick={() => {
                setShowPoly(false);
                setShowBsc(false);
                setShowAval(false);
                setShowFant(false);
                setShowOpt(true);
              }}
            >
              Optimism
            </button>
          </div>
          <hr />
          <hr />
          <div className="tableContainer">
            <table>
              <thead>
                <tr>
                  <th> Chain</th>
                  <th>Time Stamp</th>
                  <th>To</th>
                  <th>From</th>
                  <th>Hash</th>
                </tr>
              </thead>
              <tbody>
                {showBsc && <UserData users={users1} />}
                {showPolygon && <UserData users={users} />}
                {showArbi && <UserData users={users2} />}
                {showAval && <UserData users={users3} />}
                {showFant && <UserData users={users4} />}
                {showOpt && <UserData users={users5} />}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>)}
    </div>
  );
};

export default App;
